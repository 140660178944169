<template>
  <v-theme-provider dark>
    <base-section
      id="socialcertificacion"
      class="accent text-center"
      space="56"
    >

      <div class="py-0" />

      <!--<v-btn
        class="font-weight-bold"
        color="white"
        href="https://twitter.com/vuetifyjs"
        light
        min-width="168"
        target="_blank"
        x-large
      >
        Siguenos

        <v-icon right>
          mdi-open-in-new
        </v-icon>
      </v-btn>-->

      <v-container>
        <v-row justify="space-between">
          <v-col
            cols="12"
            md="5"
          >
            <base-info-cuenta>
            </base-info-cuenta>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <base-registro-medico title="! REGISTRATE !" />
          </v-col>
        </v-row>
      </v-container>
    </base-section>




  </v-theme-provider>
</template>

<script>
  import BaseRegistroMedico from "../../components/base/RegistroMedico";
  export default {
    name: 'SectiionSocialCertificacion',
    components: {BaseRegistroMedico},
    provide: {
      heading: { align: 'center' },
    },
  }
</script>
